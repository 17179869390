<template>
  <v-container fluid pa-0>
    <add-btn v-if="isSuperAdmin" @click="actions[queryParam.CREATE_ADMIN_USER].callback()" />

    <admins-filter v-model="filteredTableData" :admins="formattedTableData" />
    <admins-table :table-data="filteredTableData" :admin-actions="Object.values(actions)" />

    <component
      :is="selectedAction.component"
      v-model="showActionDialog"
      :details="getUserByAction(selectedAction.param)"
      @success="fetchAdmins"
    />
  </v-container>
</template>

<script>
import formatProduct from '@/helper/filter/formatProduct';
import AddBtn from '@/shared/components/AddBtn';
import AdminsFilter from '@/modules/Admin/components/Admins/AdminsFilter';
import AdminsTable from '@/modules/Admin/components/Admins/AdminsTable';
import CreateAdminUserDialog from '@/modules/Admin/components/Admins/Actions/CreateAdminUserDialog';
import DisableAdminUserDialog from '@/modules/Admin/components/Admins/Actions/DisableAdminUserDialog';
import ListFilterMixin from '@/mixins/ListFilterMixin';
import { NAMESPACE } from '@/modules/Admin/store';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Admins',

  mixins: [ListFilterMixin],

  data: () => ({
    filteredTableData: []
  }),

  components: {
    AddBtn,
    AdminsFilter,
    AdminsTable
  },

  computed: {
    ...mapState(NAMESPACE, ['admins']),
    formattedTableData() {
      return JSON.parse(JSON.stringify(this.admins)).map((entry) => ({
        ...entry,
        productTrans: entry.product ? formatProduct(entry.product) : ''
      }));
    },
    actions() {
      return {
        [this.queryParam.CREATE_ADMIN_USER]: {
          param: this.queryParam.CREATE_ADMIN_USER,
          component: CreateAdminUserDialog,
          permission: () => this.isSuperAdmin,
          callback: () => {
            this.setQueryParam(this.queryParam.CREATE_ADMIN_USER, true);
          }
        },
        [this.queryParam.DISABLE_ADMIN_USER]: {
          param: this.queryParam.DISABLE_ADMIN_USER,
          component: DisableAdminUserDialog,
          title: ({ userActive }) =>
            `admins.disable.trigger${userActive === false ? 'Enable' : 'Disable'}User`,
          permission: () => this.isSuperAdmin,
          callback: (admin) => {
            this.setQueryParam(this.queryParam.DISABLE_ADMIN_USER, admin.id);
          }
        }
      };
    },
    showActionDialog: {
      get() {
        return !!this.selectedAction.component;
      },
      set() {
        this.setQueryParam(this.selectedAction.param, undefined);
      }
    },
    selectedAction() {
      return (
        Object.values(this.actions).find(
          (action) =>
            !!this.$route.query[action.param] &&
            action.permission !== false &&
            this.hasFulfilledCondition(action) &&
            this.getUserByAction(action.param) !== null
        ) ?? {}
      );
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchAdmins']),

    getUserByAction(param) {
      if (this.getQueryParam(param) === true) {
        return;
      }

      return this.admins.find((user) => user.id === this.getQueryParam(param)) ?? null;
    }
  },

  created() {
    this.fetchAdmins();
  }
};
</script>
